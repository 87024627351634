import React, { useState, useEffect } from 'react';
import { getDatabase, ref, set, get } from 'firebase/database';
import app from '../fiirebase.config';
import { useNavigate, useParams } from 'react-router-dom';


const UpdateWrite = () => {
    const [inputvalue1, setinputvalue1] = useState("");
    const [inputvalue2, setinputvalue2] = useState("");
    const [inputvalue3, setinputvalue3] = useState("");
    const [inputvalue4, setinputvalue4] = useState("");

    const [previousValue1, setPreviousValue1] = useState("");
    const [previousValue2, setPreviousValue2] = useState("");
    const [previousValue3, setPreviousValue3] = useState("");
    const [previousValue4, setPreviousValue4] = useState("");

    const navigate = useNavigate();
    const { vicharId } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            const db = getDatabase(app);
            const dbRef = ref(db, "vichar/homepagevichar/" + vicharId);
            const snapshot = await get(dbRef);
            if (snapshot.exists()) {
                const targetObject = snapshot.val();
                setPreviousValue1(targetObject.discription || "");
                setPreviousValue2(targetObject.details || "");
                setPreviousValue3(targetObject.referance || "");
                setPreviousValue4(targetObject.prasenter || "");
                setinputvalue1(targetObject.discription || "");
                setinputvalue2(targetObject.details || "");
                setinputvalue3(targetObject.referance || "");
                setinputvalue4(targetObject.prasenter || "");
            } else {
                alert("Error: Data not found");
            }
        };
        fetchData();
    }, [vicharId]);

    const saveData = async () => {
        const db = getDatabase(app);
        const newDocref = ref(db, "vichar/homepagevichar/" + vicharId);
        set(newDocref, {
            discription: inputvalue1,
            details: inputvalue2,
            referance: inputvalue3,
            prasenter: inputvalue4
        }).then(() => {
            alert("Data Update successfully");
            navigate("/")
        }).catch((error) => {
            alert("Error: " + error.message);
        });
    };

    return (
        <div className='px-5 bg-orange-300 border border-gray-200 rounded-lg shadow  dark:border-gray-700'>
        <div className="max-w-sm mx-auto mt-10 mb-10 ml-5 mr-5">
            <div className="mb-5">
                <label htmlFor="input1" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Discription</label>
                <input
                    id="input1"
                    type="text"
                    value={inputvalue1}
                    onChange={(e) => setinputvalue1(e.target.value)}
                    placeholder={previousValue1 || "Enter description"}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mt-2.5"
                />
            </div>
            <div className="mb-5">
                <label htmlFor="input2" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Details</label>
                <input
                    id="input2"
                    type="text"
                    value={inputvalue2}
                    onChange={(e) => setinputvalue2(e.target.value)}
                    placeholder={previousValue2 || "Enter details"}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mt-2.5"
                />
            </div>
            <div className="mb-5">
                <label htmlFor="input3" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Referance</label>
                <input
                    id="input3"
                    type="text"
                    value={inputvalue3}
                    onChange={(e) => setinputvalue3(e.target.value)}
                    placeholder={previousValue3 || "Enter reference"}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mt-2.5"
                />
            </div>
            <div className="mb-5">
                <label htmlFor="input4" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Prasented By</label>
                <input
                    id="input4"
                    type="text"
                    value={inputvalue4}
                    onChange={(e) => setinputvalue4(e.target.value)}
                    placeholder={previousValue4 || "Enter presenter"}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mt-2.5"
                />
            </div>
            <button
                onClick={saveData}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
                Save and update the data
            </button>
        </div>
        </div>
    );
};

export default UpdateWrite;
