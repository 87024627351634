import { useState } from 'react'
import  styles from '../CSS/Navbar.module.css';
import { FaHome,FaStar,FaUser} from 'react-icons/fa';

import React from 'react'

const NavBar = () => {
    const [isActive, setIsActive] = useState(false);
   
  const toggleActiveClass = () => {
    setIsActive(!isActive);
  };

  const removeActive = () => {
    setIsActive(false)
  }

  return (
    <div className='bg-orange-300' >
     
        <nav className={`${styles.navbar}`}>
          {/* logo */}
          <a href='/' className={`${styles.logo}`}>Atish Raut </a>
          <ul className={`${styles.navMenu} ${isActive ? styles.active : ''}`}>
            <li onClick={removeActive} >
              <a href='/' className='flex'><FaHome className='mt-1 mr-1 text-xl' />Home</a>
            </li>
            {/* <li onClick={removeActive}>
              <a href='/addnew' className='flex'><FaStar className='mt-1 mr-1' />Add</a>
            </li>
            <li onClick={removeActive}>
              <a href='/edit' className='flex'><FaUser className='mt-1 mr-1' />Update</a>
            </li> */}
           
          </ul>
          <div className={`${styles.hamburger} ${isActive ? styles.active : ''}`}  onClick={toggleActiveClass}>
            <span className={`${styles.bar}`}></span>
            <span className={`${styles.bar}`}></span>
            <span className={`${styles.bar}`}></span>
          </div>
        </nav>
 
    </div>
  )
}

export default NavBar
