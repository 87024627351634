import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get, remove } from 'firebase/database';
import app from '../fiirebase.config';
import { useNavigate } from 'react-router-dom';

const Update = () => {
    const [vichararray, setVichararray] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const db = getDatabase(app);
                const dbRef = ref(db, "vichar/homepagevichar");
                const snapshot = await get(dbRef);
                if (snapshot.exists()) {
                    const myData = snapshot.val();
                    const temporaryarray = Object.keys(myData).map(myfireId => {
                        return {
                            ...myData[myfireId],
                            vicharid: myfireId
                        };
                    });
                    setVichararray(temporaryarray);
                } else {
                    setError("No data available");
                }
            } catch (err) {
                setError("Error fetching data");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const deletvichar = async (vicharidparams) => {
        try {
            const db = getDatabase(app);
            const dbRef = ref(db, "vichar/homepagevichar/" + vicharidparams);
            await remove(dbRef);
            setVichararray(prevArray => prevArray.filter(item => item.vicharid !== vicharidparams));
        } catch (err) {
            setError("Error deleting data");
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div style={{ color: 'red' }}>{error}</div>;
    }

    return (
        <div className='flex flex-wrap justify-between mt-10 px-5'>
        {vichararray.map((item, index) => (
            <div key={index} className='w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 p-3 pl-2.5 bg-orange-300 border border-gray-200 rounded-lg shadow  dark:border-gray-700 mb-4 text-left'>
                <div className='mb-2'>
                    <h5 className='font-bold mb-1'>Description :</h5>
                    <p>{item.discription}</p>
                </div>
                <div className='mb-3'>
                    <h5 className='font-bold mb-1'>Details :</h5>
                    <p>{item.details}</p>
                </div>
                <div className='flex'>
                    <h5 className='font-bold'>Referance : </h5>
                    <p className='ml-2'>{item.referance}</p>
                </div>
                <div className='flex mb-2'>
                    <h5 className='font-bold'>Presented By : </h5>
                    <p className='ml-2'>{item.prasenter}</p>
                </div>
                <div className='flex '>
                <button className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800' onClick={() => navigate(`/update/${item.vicharid}`)}>Update</button>
                <button className='focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900' onClick={() => deletvichar(item.vicharid)}  >Delete</button>
                </div>
            </div>
        ))}
    </div>
    
    );
};

export default Update;
