import React, { useState, useEffect } from "react";
import { getDatabase, ref, get } from "firebase/database";
import app from "../fiirebase.config";

const DisplayHomedata = () => {
  const [vichararray, setVichararray] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const db = getDatabase(app);
        const dbRef = ref(db, "vichar/homepagevichar");
        const snapshot = await get(dbRef);
        if (snapshot.exists()) {
          setVichararray(Object.values(snapshot.val()));
        } else {
          setError("No data available");
        }
      } catch (err) {
        setError("Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div style={{ color: "red" }}>{error}</div>;
  }

  return (
    <div className="flex flex-wrap justify-between mt-10 px-5">
      {vichararray.map((item, index) => (
        <div
          key={index}
          className="w-full bg-orange-300 sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 p-3 pl-2.5  border border-gray-200 rounded-lg shadow  dark:border-gray-700 mb-4 text-left"
        >
          <div className="mb-2">
            <h5 className="font-bold mb-1">Description :</h5>
            <p>{item.discription}</p>
          </div>
          <div className="mb-3">
            <h5 className="font-bold mb-1">Details :</h5>
            <p>{item.details}</p>
          </div>
          <div className="flex">
            <h5 className="font-bold">Referance : </h5>
            <p className="ml-2">{item.referance}</p>
          </div>
          <div className="flex">
            <h5 className="font-bold">Prasented By : </h5>
            <p className="ml-2"> {item.prasenter} </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DisplayHomedata;
