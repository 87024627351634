import React, { useState } from 'react'
import { getDatabase,ref,set,push } from 'firebase/database'
import app from '../fiirebase.config'

import { useNavigate } from 'react-router-dom';


const AddingForm = () => {
    let [inputvalue1,setinputvalue1]= useState("");
    let [inputvalue2,setinputvalue2]= useState("");
    let [inputvalue3,setinputvalue3]= useState("");
    let [inputvalue4,setinputvalue4]= useState("");

    const navigate = useNavigate()

    const saveData=async () => {
        const db = getDatabase(app);
        const newDocref = push(ref(db,"vichar/homepagevichar"))
        set(newDocref,{
            discription:inputvalue1,
            details:inputvalue2,
            referance:inputvalue3,
            prasenter:inputvalue4
        }).then(() =>{
            alert("data send sucessfull")
            navigate("/")
        }).catch((error) => {
            alert("error:",error.message)
        })
    }
  return (
    <div className='px-5 bg-orange-300 border border-gray-200 rounded-lg shadow  dark:border-gray-700'>
    <form className="max-w-sm mx-auto mt-10 mb-10 ml-5 mr-5">
    <div className="mb-5">
      <label htmlFor="input1" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Discription</label>
      <input
        type="text"
        id="input1"
        value={inputvalue1}
        onChange={(e) => setinputvalue1(e.target.value)}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="Enter value"
        required
      />
    </div>
    <div className="mb-5">
      <label htmlFor="input2" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Details</label>
      <input
        type="text"
        id="input2"
        value={inputvalue2}
        onChange={(e) => setinputvalue2(e.target.value)}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="Enter value"
        required
      />
    </div>
    <div className="mb-5">
      <label htmlFor="input3" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Referance</label>
      <input
        type="text"
        id="input3"
        value={inputvalue3}
        onChange={(e) => setinputvalue3(e.target.value)}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="Enter value"
        required
      />
    </div>
    <div className="mb-5">
      <label htmlFor="input4" className="block mb-2 left-0 text-sm font-medium text-gray-900 dark:text-white">Prasent By</label>
      <input
        type="text"
        id="input4"
        value={inputvalue4}
        onChange={(e) => setinputvalue4(e.target.value)}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="Enter value"
        required
      />
    </div>
    <button
      type="button"
      onClick={saveData}
      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
    >
      Save the data
    </button>
  </form>
  </div>
  )
}

export default AddingForm
