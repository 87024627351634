
import './App.css';

import NavBar from './Conponent/Navbar';
import Container from './Conponent/Container';

function App() {
  return (
    <div className="App">
    <NavBar />
   <Container/>
    </div>
  );
}

export default App;
