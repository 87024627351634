import React from 'react'
import { Route, Routes } from 'react-router-dom'

import AddingForm from './AddingForm'
import UpdateWrite from './UpdateWrite'
import Update from './Update'
import DisplayHomedata from './DisplayHomedata'


const Container = () => {
  return (
    <div>
        <Routes>
            <Route path='/' element={<DisplayHomedata/>}></Route>
            <Route path='/addnew' element={<AddingForm/>}></Route>
            <Route path='/edit' element={<Update/>}></Route>
            <Route path='/update/:vicharId' element={<UpdateWrite/>}></Route>
          
        </Routes>
      
    </div>
  )
}

export default Container
